/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// plugins
import moment from "moment";
import { useTranslation } from "react-i18next";

/*Prime Component*/
import { Dropdown } from "primereact/dropdown";
import { Card } from "primereact/card";
import { Button } from "primereact/button";

/*Component*/
import ContentLoading from "../loading/ContentLoading";

/*Service*/
import { PersonnelGetList } from "../../service/personnel";
import { LeadGetList } from "../../service/lead";
import { NotesUserGet } from "../../service/note";
import { momentDateFormat } from "../../constants/string";

const AllCustomerNote = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [allNoteData, setAllNoteData] = useState([]); // Tüm Müşteri Notları Listesi
  const [personnelList, setPersonnelList] = useState({}); //Tüm Personel Listesi
  const [leadList, setLeadList] = useState({}); //Tüm Lead Listesi
  const [personnelData, setPersonnelData] = useState(0); //Seçilen Personel Datası
  const [leadData, setleadData] = useState(0); //Seçilen Personel Datası

  useEffect(() => {
    PersonnelGetList().then((res) => {
      //Personel Listesini Getirelim
      setPersonnelList(res);
      setIsLoading(false);
    });
    LeadGetList().then((res) => {
      //Lead Listesini Getirelim
      setIsLoading(false);
      setLeadList(res);
    });
  }, []);

  //Personel Seçilince Tabloyu günceller (Tüm Müşteri notları)
  const logOnClick = (e) => {
    setIsLoading(true);
    NotesUserGet(e).then((res) => {
      setIsLoading(false);
      setAllNoteData(res);
    });
  };

  //Dropdown Search Code Start
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="country-item country-item-value">
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };
  const countryOptionTemplate = (option) => {
    return (
      <div className="country-item">
        <div>{option.name}</div>
      </div>
    );
  };
  //Dropdown Search Code Start end

  return (
    <>
      {isLoading ? (
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <ContentLoading />
            </div>
          </div>
        </div>
      ) : (
        <div className="p-fluid">
          <div className="col">
            <div className="card">
              <div className="col-12">
                <h5>{t("anasayfa.tumMusteriNotlariBaslik")}</h5>
                <p>{t("anasayfa.tumMusteriNotlariAciklama")}</p>
                <div className="grid">
                  <div className="col-12 md:col-6">
                    <div className="field">
                      <label htmlFor="personnelEMail">{t("anasayfa.tumMusteriNotlariPersonel")}</label>
                      <Dropdown
                        value={personnelData}
                        options={personnelList}
                        onChange={(e) => {
                          logOnClick(e.value);
                          setPersonnelData(e.value);
                        }}
                        optionLabel="name"
                        optionValue="id"
                        filter
                        showClear
                        filterBy="name"
                        placeholder={t("global.placeholderSelect")}
                        valueTemplate={selectedCountryTemplate}
                        itemTemplate={countryOptionTemplate}
                      />
                    </div>
                  </div>
                </div>
                <div className="grid">
                  {allNoteData.length > 0 ? (
                    allNoteData.map((item, i) => {
                      return (
                        <div className="col-12 md:col-4" key={i}>
                          <Card
                            title={item.title}
                            subTitle={t("anasayfa.tumMusteriNotlariOlusturan") + item.createdUser}
                            style={{ width: "100%" }}
                          >
                            <p className="m-0" style={{ lineHeight: "1.5" }}>
                              {item.content}
                            </p>
                            <div className="p-card-subtitle mt-4">
                            {t("anasayfa.tumMusteriNotlariOlusturmaTarihi")}<br></br>
                              <b>
                                {moment(item.createdDate).format(
                                  momentDateFormat
                                )}
                              </b>
                            </div>
                            <div className="p-card-subtitle mt-1">
                            {t("anasayfa.tumMusteriNotlariMusteri")}<br></br>
                              <b>
                                {item.customer.name + item.customer.surname}
                              </b>
                            </div>
                            <Link
                              className="p-button-info mr-2"
                              to={`/musteri/${item.customer.id}`}
                            >
                              <Button
                                label="Müşteri Detayına Git"
                                className=" p-button-info mr-2 mt-3"
                              />
                            </Link>
                          </Card>
                        </div>
                      );
                    })
                  ) : (
                    <h5 style={{ marginTop: "30px" }}>
                      {t("anasayfa.tumsMusteriNotlariListeBaslik")}
                    </h5>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(AllCustomerNote, comparisonFn);
